import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Button from "./button"

const BlogPostListing = ({ title, description, slug }) => (
  <Wrapper to={slug}>
    <h3>{title}</h3>
    <p>{description}</p>
    <Button as={Link} to={slug}>
      Read article ⮕
    </Button>
  </Wrapper>
)

export default BlogPostListing

const Wrapper = styled.div`
  display: block;
  padding: 1em 0 1.6em;
  border-bottom: 1px solid var(--grey);
  &:last-child {
    border-bottom: none;
  }

  h3 {
    color: var(--grey);
    font-size: 1.4em;
  }
`
