import styled from "styled-components"
import media from "./media"

const PageIntro = styled.div`
  padding: 0 var(--globalPadding);
  h2 {
    font-family: var(--trim);
    font-size: 4vw;
    text-align: left;
    line-height: 1;
    color: var(--blue);
    margin: 1em 0;
    ${media.down("m")} {
      font-size: 7vw;
    }
    ${media.up("l")} {
      font-size: 4em;
    }
  }
`

export default PageIntro
