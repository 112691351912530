import styled from "styled-components"

import media from "./media"

const Content = styled.div`
  width: 100%;
  padding: 0 var(--globalPadding) 0;
  ul,
  ol {
    padding-left: 1.25em;
  }

  .highlight {
    padding: 1em;
    background-color: #e5e5e5;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    ${media.down("s")} {
      flex-direction: column;
    }
  }

  .col {
    width: calc(50% - (var(--globalPadding) / 2));
    ${media.down("s")} {
      width: 100%;
    }
  }

  .content {
    & *:first-child {
      margin-top: 0;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 0.5em;
      line-height: 1;
      ${media.down("m")} {
        font-size: 1.7em;
      }
    }

    h4 {
      color: var(--blue);
      font-size: 1.75rem;
      margin-bottom: 0.5em;
      ${media.down("m")} {
        font-size: 1.5em;
      }
    }

    h5 {
      font-size: 1.5rem;
      margin-bottom: 0.5em;
      ${media.down("m")} {
        font-size: 1.2em;
      }
    }

    h6 {
      font-size: 1.25rem;
      text-decoration: underline;
      margin-bottom: 0.5em;
      font-weight: 800;
      letter-spacing: -0.05em;
      ${media.down("m")} {
        font-size: 1.1em;
      }
    }

    li {
      margin-bottom: 1em;
    }

    a {
      color: var(--blue);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &:after {
        content: "\2192";
      }
    }
  }
`

/* .thankyou {}
		display none
		body.submit-successful &
			display block

	&-highlight
		background-color lighten($grey, 80%)
		padding 1em */

/* .mobile-only
	margin-top 2em
	display none
	${media.}
		display flex
		justify-content center */

export default Content
