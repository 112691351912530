import React from "react"

import { GolbalStateContext } from "../context"

const MenuReset = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setGlobalState] = React.useContext(GolbalStateContext)
  React.useEffect(() => {
    setGlobalState(state => ({
      ...state,
      menuOpen: false,
    }))
  }, [setGlobalState])

  return null
}
export default MenuReset
